import React from "react";
import ProductTemplate from "./product";
//import Image from "@components/common/CloudinaryImage";
import { Link } from "gatsby";
import Title from "@components/common/Title";
import Text from "@components/common/Text";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <>
            <ProductTemplate item={item}>
                {/** Qué es¿? */}
                <div className="container lg:px-0 py-20">
                    <Title>Campus Online de Casos Pràctics d'Entrevista Policial</Title>
                    <Text>
                        T'agradaria tenir en un campus online els casos pràctics oficials de les
                        últimes convocatòries amb explicacions il·lustrades?
                        <br />
                        <br />
                        Et presentem el millor campus, un campus únic on tindràs consells
                        d'entrevistes, tipologies d'entrevistes i els casos pràctics separats per
                        temàtiques.
                        <br />
                        <br />
                        -Casos de mediació
                        <br />
                        <br />
                        -Casos assistencials
                        <br />
                        <br />
                        -Casos amb infraccions
                        <br />
                        <br />
                        -Casos com a civils
                        <br />
                        <br />
                        -Casos de trànsit
                        <br />
                        <br />
                        -Casos corporatius
                        <br />
                        <br />
                        Una selecció acurada dels casos més importants.
                        <br />
                        <br />
                        A totes les assignatures trobareu l'enunciat del cas i l'explicació.
                        <br />
                        <br />
                        El temps del curs són seixanta dies, que és el temps que considerem ideal
                        per interioritzar els mecanismes del curs.
                        <br />
                        <br />
                        Estàs preparat per començar?
                    </Text>
                </div>

                {/** ¿Cómo accedo? */}
                <div className="bg-gray-100 bg-opacity-50">
                    <div className="container lg:px-0 py-20">
                        <Title>¿Cómo accedo?</Title>
                        <Text className="text-justify">
                            Una vez adquirido a través de la sección{" "}
                            <Link to="/tienda/" className="default-link">
                                Tienda
                            </Link>{" "}
                            recibirás un correo con tus credenciales para poder acceder al curso. En
                            el menú superior encontrarás la opción{" "}
                            <a
                                href="https://cursos.iopos.es"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="default-link"
                            >
                                Plataforma de cursos
                            </a>{" "}
                            que te dirigirá hacia la plataforma donde están almacenados todos los
                            cursos y formaciones.
                        </Text>
                    </div>
                </div>
            </ProductTemplate>
        </>
    );
};

export default Product;
